<template>
  <section class="container">
    <div class="page-wrapper">
      <div v-if="title">
        <el-row type="flex" align="middle" justify="space-between">
          <div class="page-wrapper__title df w-100 jc-sb ai-c f-wrap">
            <h2 class="tt-up">{{ title }}</h2>
            <p v-if="link" class="fs-20 t-greenLight fw-b m-0">
              <slot name="link" />
              <span class="bb cp" @click="onClick">{{ link }}</span>
            </p>
          </div>
        </el-row>
        <slot name="subtitle" />
        <el-divider />
        <p v-if="text" class="page-wrapper__text fs-20 t-grey mb-50 fw-l">
          <span :class="{ 'bb cp': click }" @click="onClick">{{ text }}</span>
        </p>
      </div>
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: 'PageWrapper',
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    click: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      if (this.click || this.link) this.$emit('click')
    }
  }
}
</script>

<style lang="sass">
@import "~@/assets/styles/mixins.sass"

.page-wrapper
  margin: 70px auto
  @include to(768px)
    margin: 45px auto
  &__title,
  &__text
    @include to(768px)
      padding: 0 20px
</style>
