<template>
  <el-row class="getting-inn">
    <el-form-item :prop="prop" :label="label">
      <el-input
        v-model="inn"
        placeholder=""
        :disabled="loading"
        class="getting-inn-field"
        @keyup.enter.native="handleGetINN()"
      >
        <el-button
          slot="append"
          :disabled="loading || !inn"
          icon="el-icon-search"
          @click="handleGetINN()"
        />
      </el-input>
    </el-form-item>
    <template v-if="name">
      <el-row type="flex" class="mb-5 mt-10">
        <span class="mr-10">Название компании:</span>
        <span>{{ name }}</span>
      </el-row>
      <el-row class="mb-10">
        <span class="fs-12 orange mr-10">Данные устарели?</span>
        <el-button type="text" :disabled="loading" @click="handleGetINN(1)">
          Запросить новые
        </el-button>
      </el-row>
    </template>
    <p v-if="!name && label" class="fs-14 t-green w-100 mb-30">
      Для аккредитации у компании экспортера укажите ИНН вашей компании
    </p>
  </el-row>
</template>

<script>
import ApiFactory from '@/api/ApiFactory'

const RegistryApi = ApiFactory.get('registry')

export default {
  name: 'GettingInn',
  props: {
    label: {
      type: String,
      default: ''
    },
    prop: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      inn: '',
      name: '',
      loading: false
    }
  },
  watch: {
    item(val) {
      this.inn = val ? val.inn : ''
      this.name = val ? val.name : ''
    }
  },
  methods: {
    handleGetINN(refresh = 0) {
      const { inn } = this
      if (inn) {
        this.loading = true
        RegistryApi.checkInn({ inn, refresh })
          .then(({ data }) => {
            this.name = data.name
            this.$emit('change', data.id, this.prop)
          })
          .catch(err => {
            this.errHandler(err)
            this.name = ''
          })
          .finally(() => (this.loading = false))
      } else {
        this.name = ''
      }
    }
  }
}
</script>

<style lang="sass">
.getting-inn
  .getting-inn-field
    .el-input__inner
      border-radius: 30px 0 0 30px
      border-right: none
    .el-input-group__append
      border-radius: 0 30px 30px 0
      border: 1px solid #C7C7C7
      button
        padding: 10px
        &:focus, &:hover
          border-radius: 0 30px 30px 0
          background: #F5F7FA
        i
          font-size: 25px
  .is-error
    .el-input-group__append
      border-color: #F56C6C
      i
        color: #F56C6C
</style>
