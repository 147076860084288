<template>
  <el-form
    ref="authForm"
    :model="form"
    :rules="rules"
    label-position="top"
    class="user-form i-components"
    @submit.native.prevent
  >
    <el-form-item prop="first_name" label="Ваше имя" class="mr-70 mr-m-0">
      <el-input
        v-model="form.first_name"
        autocomplete="nope"
        @change="onChange($event, 'first_name')"
      />
    </el-form-item>
    <el-form-item prop="last_name" label="Фамилия (инициалы)">
      <el-input
        v-model="form.last_name"
        :maxlength="1"
        autocomplete="nope"
        class="short"
        @change="onChange($event, 'last_name')"
      />
    </el-form-item>
    <p class="fs-14 t-green w-100 mb-30">
      Политика нашего сервиса. Анонимность для всех участников. <br />
      Но мы хотим обращаться к вам по имени. Укажите ваши имя и инициалы
      фамилии.
    </p>
    <el-form-item prop="login" label="Ваш телефон" class="mr-70 mr-m-0">
      <input
        v-if="profile"
        class="el-input__inner disabled"
        v-mask="'+7 (###) ###-##-##'"
        :value="form.login"
        masked="true"
        :readonly="true"
      />
      <el-input
        v-else
        v-model="form.login"
        v-mask="'+7 (###) ###-##-##'"
        autocomplete="nope"
        @change="onChange($event, 'login')"
      />
    </el-form-item>
    <el-form-item prop="email" label="Ваш email">
      <el-input
        v-model="form.email"
        autocomplete="nope"
        @change="onChange($event, 'email')"
      />
    </el-form-item>
    <p class="fs-14 t-green w-100 mb-30">
      Мы хотим быть уверены что это именно вы оставляете заявки на участие в
      тендере. <br />
      Поэтому используем двухфакторную авторизацию. А ваш email нужен что бы
      оперативно информировать об изменениях
    </p>
    <loader-wrapper
      v-if="profile"
      :loading="isLoading || loading"
      class="w-100 mb-30"
    >
      <span v-if="userInfo.email_verified > 0" class="accepted">
        Email подтвержден
      </span>
      <template v-else>
        <div v-if="isSent">
          <p class="m-0 accepted">
            Письмо отправлено, проверьте ваш email
            <span class="fs-20 ml-20">{{ counter | toTimer }}</span>
          </p>
        </div>
        <template v-else>
          <p class="mt-0 declined">
            Email не подтвержден, уведомления не будут приходить на почту
          </p>
          <span class="bb cp t-greenLight fw-b" @click="onRequestConfirm">
            Отправить повторное письмо на подтверждение
          </span>
        </template>
      </template>
    </loader-wrapper>
    <el-row v-if="userInfo.organization" class="fs-20 t-greyDark">
      Название организации:
      <span class="fw-b">{{ userInfo.organization.name }}</span>
      , ИНН:
      <span class="fw-b">{{ userInfo.organization.inn }}</span>
    </el-row>
    <getting-inn v-else label="ИНН:" prop="org_id" @change="onChange" />
    <el-col class="mt-40">
      <i-button
        :text="profile ? 'Сохранить' : 'Зарегистироваться'"
        :loading="isLoading || loading"
        :disabled="isLoading || loading"
        width="250"
        @click="onClick"
      />
    </el-col>
  </el-form>
</template>

<script>
import { mapGetters } from 'vuex'
import IButton from '@/components/Ui/Button'
import LoaderWrapper from '@/components/Wrappers/LoaderWrapper'
import GettingInn from '@/components/Reusable/GettingInn'
import ApiFactory from '@/api/ApiFactory'
import { userFormRules, loginRules } from './constants'

const UsersApi = ApiFactory.get('users')

export default {
  name: 'UserForm',
  components: { IButton, LoaderWrapper, GettingInn },
  props: {
    obj: {
      type: Object,
      default: () => ({})
    },
    profile: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rules: this.profile ? userFormRules : { ...userFormRules, ...loginRules },
      counter: 300,
      isLoading: false,
      isSent: false,
      timer: null
    }
  },
  computed: {
    ...mapGetters({
      getLoading: 'auth/getLoading',
      userInfo: 'profile/userInfo'
    }),
    form() {
      return this.obj
    }
  },
  methods: {
    onChange(val, key) {
      this.$emit('set', { val, key })
      this.$refs.authForm.clearValidate(key)
    },
    onClick() {
      this.$refs.authForm.validate(valid => {
        if (valid) {
          this.$emit('next')
          if (this.profile) {
            this.isSent = false
            this.counter = 300
            clearTimeout(this.timer)
          }
        }
      })
    },
    checkTimer() {
      if (this.counter) {
        this.timer = setTimeout(() => {
          --this.counter
          this.checkTimer()
        }, 1000)
      } else this.isSent = false
    },
    onRequestConfirm() {
      this.isLoading = true
      UsersApi.requestConfirmEmail()
        .then(({ data }) => {
          if (data.success) {
            this.$message.success('Письмо отправлено, проверьте ваш email')
            this.counter = 300
            this.checkTimer()
            this.isSent = true
          }
        })
        .catch(err => this.errHandler(err))
        .finally(() => (this.isLoading = false))
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  }
}
</script>

<style lang="sass">
@import "~@/assets/styles/mixins.sass"

.user-form
  display: flex
  flex-wrap: wrap
  @include to(768px)
    padding: 0 20px
  .el-form-item
    width: 320px
    @include to(768px)
      width: 100%
    .short
      width: 100px
      @include to(768px)
        width: 100%
      input
        text-transform: uppercase
        text-align: center
</style>
