<template>
  <div ref="loader">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'LoaderWrapper',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    body: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    lock: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    spinner: {
      type: String,
      default: ''
    },
    background: {
      type: String,
      default: ''
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loadingObj: null
    }
  },
  mounted() {
    this.loading ? this.loaderOn() : this.loaderOff()
  },
  watch: {
    loading(newVal) {
      newVal ? this.loaderOn() : this.loaderOff()
    }
  },
  methods: {
    loaderOn() {
      this.loadingObj = this.$loading({
        target: this.fullscreen ? 'document.body' : this.$refs.loader,
        body: this.body,
        fullscreen: this.fullscreen,
        lock: this.lock,
        text: this.text,
        spinner: this.spinner,
        background: this.background,
        customClass: this.customClass
      })
    },
    loaderOff() {
      if (this.loadingObj !== null) this.loadingObj.close()
    }
  }
}
</script>

<style lang="sass">
.mini-spinner .el-loading-spinner
  margin-top: -8px
</style>
