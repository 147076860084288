export const userFormRules = {
  first_name: {
    required: true,
    message: 'Необходимо заполнить имя',
    trigger: 'blur'
  },
  last_name: {
    required: true,
    message: 'Необходимо заполнить инициалы',
    trigger: 'blur'
  },
  email: [
    { required: true, message: 'Необходимо заполнить email', trigger: 'blur' },
    {
      type: 'email',
      message: 'Введите корректный email',
      trigger: ['blur']
    }
  ],
  org_id: {
    required: true,
    message: 'Необходимо выполнить поиск по ИНН',
    trigger: 'blur'
  }
}

export const loginRules = {
  login: [
    {
      required: true,
      message: 'Необходимо заполнить телефон',
      trigger: ['blur', 'change']
    },
    {
      min: 18,
      message: 'Телефон заполнен не полностью',
      trigger: ['blur']
    }
  ]
}

export const filterStatusOptions = [
  { id: 1, label: 'Не состоит в группах', value: 0 },
  { id: 2, label: 'Состоит в какой-то группе', value: 1 }
]

export const perPageOptions = [5, 10, 20]

export const bidFormRules = {
  fio: {
    required: true,
    message: 'Необходимо заполнить Ваше Имя',
    trigger: ['blur', 'change']
  },
  phone: [
    {
      required: true,
      message: 'Необходимо заполнить телефон',
      trigger: ['blur', 'change']
    },
    {
      min: 18,
      message: 'Телефон заполнен не полностью',
      trigger: ['blur']
    }
  ],
  inn: {
    required: true,
    message: 'Необходимо заполнить ИНН',
    trigger: ['blur', 'change']
  },
  company_name: {
    required: true,
    message: 'Необходимо заполнить название организации',
    trigger: ['blur', 'change']
  },
  job_format: {
    required: true,
    message: 'Выберите формат Вашей работы',
    trigger: 'change'
  }
}

export const getGroupTableFields = (type = '') => {
  return tables[`ugFields${type}`]
}

const tables = {
  ugFields: [
    { prop: 'id', label: 'ID', width: 80 },
    { prop: 'created_at', label: 'ДАТА СОЗДАНИЯ', width: 130 },
    { prop: 'name', label: 'НАЗВАНИЕ ГРУППЫ', minWidth: 100 },
    { prop: 'description', label: 'ОПИСАНИЕ', minWidth: 150 },
    {
      prop: 'bidders_count',
      label: 'КОЛ-ВО',
      span: 'УЧАСТНИКОВ',
      minWidth: 130
    }
  ],
  ugFieldsMobile: [
    { prop: 'id', label: 'ID', width: 80 },
    { prop: 'name', label: 'НАЗВАНИЕ ГРУППЫ' },
    { prop: 'bidders_count', label: 'КОЛ-ВО', span: 'УЧАСТНИКОВ' }
  ]
}
