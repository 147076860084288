<template>
  <page-wrapper
    :title="title"
    text="Регистрация доступна для новых перевозчиков. Если у вас заключен договор то"
    link="Выход"
    class="profile-page"
    @click="logout"
  >
    <span v-if="!isBidder" slot="link" class="pp-link" @click="toSettings">
      <span class="bb cp">Настройки</span>
    </span>
    <user-form
      :obj="form"
      :load="isLoading"
      profile
      @set="onSetField"
      @next="onSave"
    />
  </page-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PageWrapper from '@/components/Wrappers/PageWrapper'
import UserForm from '@/components/TablesFormsFilters/UserForm'
import ApiFactory from '@/api/ApiFactory'

const ProfileApi = ApiFactory.get('profile')

export default {
  name: 'Profile',
  components: { PageWrapper, UserForm },
  data() {
    return {
      step: 1,
      isLoading: false,
      form: {
        login: '',
        email: '',
        first_name: '',
        last_name: '',
        org_id: ''
      }
    }
  },
  watch: {
    'user.id': {
      immediate: true,
      handler(id) {
        if (id) this.form = this.updateObject(this.form, this.user)
      }
    }
  },
  computed: {
    ...mapGetters('libs', ['getOrgName']),
    title() {
      let title = 'ПРОФИЛЬ ПОЛЬЗОВАТЕЛЯ'
      if (!this.isBidder) {
        const name = this.getOrgName(this.user.org_id)
        if (name) title += ', компания ' + name
      }
      return title
    }
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      getProfile: 'profile/getProfile'
    }),
    onSave() {
      this.isLoading = true
      ProfileApi.updateProfile(this.form)
        .then(() => {
          this.getProfile()
          this.$message.success('Профиль успешно обновлен!')
        })
        .catch(err => this.errHandler(err))
        .finally(() => (this.isLoading = false))
    },
    onSetField({ val, key }) {
      this.form[key] = val
    },
    toSettings() {
      this.$router.push({ name: 'Settings' })
    }
  }
}
</script>

<style lang="sass">
.profile-page
  .pp-link
    position: relative
    margin-right: 40px
    padding-left: 35px
    &:before
      content: ''
      position: absolute
      background: url('~@/assets/imgs/icons/settings-icon.svg') no-repeat
      background-size: contain
      left: 0
      top: 0
      width: 23px
      height: 23px
</style>
